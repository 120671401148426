import styled from 'styled-components';
import { FlexRow } from '../components';

export const Container = styled.div`
  padding: 32px;
  @media screen and (min-width: 768px) {
    padding: 32px 64px;
  }
`;

export const FormWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const RightPane = styled.div`
  flex: 0 40%;
  padding: 40px;
  height: 100vh;
  overflow: scroll;
  background: #fff;
  @media screen and (min-width: 768px) {
    background: #f5f5f5;
  }
`;

export const LeftPane = styled.div`
  flex: 0 60%;
  padding: 64px;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  background: #f5f5f5;
  @media screen and (min-width: 768px) {
    background: #fff;
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  max-width: 550px;
`;

export const SelectRow = styled(FlexRow)`
  border-bottom: 1px solid #e4e4e4;
  margin-top: 16px;
`;
