import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ADD_GUEST } from '../graphql/mutations';
import { GET_ORDER_TYPE_BY_ID } from '../graphql/queries';

export const useGuestQuestionsController = () => {
  let { orderId } = useParams();

  const [
    addGuest,
    { data: guestData, loading: guestLoading, error: guestError },
  ] = useMutation(ADD_GUEST);

  const ogFormik = useFormik({
    initialValues: {
      restrictions: '',
      lunch: '',
    },
    validationSchema: Yup.object<any>({
      restrictions: Yup.string().required(),
      lunch: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const docuSignFormik = useFormik({
    initialValues: {
      restrictions: '',
    },
    validationSchema: Yup.object<any>({
      restrictions: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const budgetFormik = useFormik({
    initialValues: {
      lunch: '',
      quesadilla: false,
      alPastor: false,
      carneAsada: false,
      fish: false,
      shrimp: false,
      imvegan: false,
    },
    validationSchema: Yup.object<any>({
      lunch: Yup.string().required(),
      quesadilla: Yup.boolean(),
      alPastor: Yup.boolean(),
      carneAsada: Yup.boolean(),
      fish: Yup.boolean(),
      shrimp: Yup.boolean(),
      imvegan: Yup.boolean(),
    }),
    onSubmit: () => {},
  });

  const { data, loading, error } = useQuery(GET_ORDER_TYPE_BY_ID, {
    variables: { id: orderId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const guestFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object<any>({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().email().required(),
    }),
    onSubmit: () => {},
  });

  const luxeFormik = useFormik({
    initialValues: {
      lunch: '',
      restrictions: '',
    },
    validationSchema: Yup.object<any>({
      lunch: Yup.string().required(),
      restrictions: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const reload = () => {
    window.location.reload();
  };

  return {
    luxeFormik,
    guestFormik,
    budgetFormik,
    docuSignFormik,
    reload,
    ogFormik,
    data,
    error,
    loading,
    guestData,
    guestError,
    guestLoading,
    addGuest,
    orderId,
  };
};
