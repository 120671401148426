import React from 'react';
import { ReactComponent as DotLoader } from '../../../assets/dot-loader.svg';

interface Props {
  disabled: boolean;
  loading: boolean;
}

export const PaymentForm: React.FC<Props> = ({ disabled, loading }) => {
  return (
    <>
      <form id="payment-form">
        <div id="card-container"></div>
        <button
          disabled={disabled || loading}
          id="card-button"
          type="button"
          className="full_width"
        >
          {loading ? <DotLoader /> : 'Complete Payment'}
        </button>
      </form>
      <div id="payment-status-container"></div>
    </>
  );
};
