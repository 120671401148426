import styled from 'styled-components';

export const Box = styled.div<{ loading: boolean }>`
  > div > div {
    min-height: 45px;
  }
  ${(props) =>
    props.loading
      ? `  > div > div > div > div {
    right: 8px;
  }
  `
      : undefined}

  ${(props) =>
    props.loading
      ? `   > div > div > div > div > svg{
    width: 20px
  }
  `
      : undefined}
`;
