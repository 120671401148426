import styled from 'styled-components';
import { FlexRow, PInfo, P } from '../../../components';
import background from '../../../assets/square-product.jpg';

export const ProductImage = styled.div`
  background-color: #ffffff;
  width: 75px;
  min-width: 75px;
  height: 75px;
  margin-right: 16px;
  background-image: url(${background});
  background-position: center center;
  background-size: cover;
`;

export const ImageRow = styled(FlexRow)`
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledTitle = styled(P)`
  margin: 0;
`;
export const StyledSubtitle = styled(PInfo)`
  margin: 0;
`;

export const UpgradeRow = styled(FlexRow)`
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;
