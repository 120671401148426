import { gql } from '@apollo/client';

export const COMPLETE_PAYMENT = gql`
  mutation CompletePayment($cardPaymentInput: CardPaymentInput!) {
    squareCheckout(cardPaymentInput: $cardPaymentInput) {
      id
      status
      receiptUrl
    }
  }
`;

export const CREATE_OPEN_ORDER = gql`
  mutation CreateOpenOrder($createOpenOrderInput: CreateOpenOrderInput!) {
    createOpenOrder(createOpenOrderInput: $createOpenOrderInput) {
      id
      totalAmountPayable
      discounts {
        name
        amount
      }
      items {
        name
        quantity
        amount
      }
    }
  }
`;

export const CREATE_DRAFT_ORDER = gql`
  mutation CreateDraftOrder($draftOrderInput: DraftOrderInput!) {
    createDraftOrder(draftOrderInput: $draftOrderInput) {
      id
    }
  }
`;

export const ANSWER_TRIP_QUESTIONS = gql`
  mutation AnswerQuestions($tripQuestionsInput: TripQuestionsInput!) {
    answerTripQuestions(tripQuestionsInput: $tripQuestionsInput) {
      success
    }
  }
`;

export const ADD_GUEST = gql`
  mutation AddGuest($guestInput: GuestInput!) {
    addGuest(guestInput: $guestInput) {
      success
    }
  }
`;

export const APPLY_DISCOUNT = gql`
  mutation ApplyDiscount($discountInput: DiscountInput!) {
    applyDiscountToOrder(discountInput: $discountInput) {
      success
      reason
      totalAmount
      appliedDiscount
    }
  }
`;
