import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  FlexColumn,
  StyledInput,
  StyledLabel,
  FlexRow,
} from '../../../components';
import { Box } from './BillingAddress.styles';

interface BillingAddressFormProps {
  formik: any;
  handleAddressSelect: (val: any) => void;
  googlePlace: any;
  loading: boolean;
}

export const BillingAddress: React.FC<BillingAddressFormProps> = ({
  formik,
  handleAddressSelect,
  googlePlace,
  loading,
}) => {
  return (
    <FlexColumn>
      <FlexRow className="margin_bottom_16 full_width stack_mobile">
        <FlexColumn>
          <StyledLabel htmlFor="cardholderName">Name</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('cardholderName')}
            type="text"
            id="billing-cardholderName"
            placeholder="Name on card"
            name="cardholderName"
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow className="margin_bottom_16 stack_mobile">
        <FlexColumn className="margin_right_16">
          <StyledLabel htmlFor="addressLine1">Address</StyledLabel>
          <Box loading={loading}>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyAl7YNh_pdQGAUbIJtWGZyPZsHcuUOLLns"
              selectProps={{
                value: googlePlace,
                onChange: handleAddressSelect,
                placeholder: 'Address',
              }}
            />
          </Box>
          <StyledInput
            className="hide"
            {...formik.getFieldProps('addressLine1')}
            type="text"
            id="billing-a"
            placeholder="Address"
            name="addressLine1"
          />
        </FlexColumn>
        <FlexColumn>
          <StyledLabel htmlFor="addressLine2">Address 2</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('addressLine2')}
            type="text"
            id="billing-a2"
            placeholder="Apt, suite, etc. (optional)"
            name="addressLine2"
          />
        </FlexColumn>
      </FlexRow>
      <FlexColumn className="margin_bottom_16">
        <FlexRow className="margin_bottom_16 stack_mobile">
          <FlexColumn className="margin_right_16">
            <StyledLabel htmlFor="city">City</StyledLabel>
            <StyledInput
              {...formik.getFieldProps('city')}
              type="text"
              id="billing-city"
              placeholder="City"
              name="city"
            />
          </FlexColumn>
          <FlexColumn>
            <StyledLabel htmlFor="state">State</StyledLabel>
            <StyledInput
              {...formik.getFieldProps('state')}
              type="text"
              id="billing-state"
              placeholder="State"
              name="state"
            />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
      <FlexRow className="margin_bottom_16 stack_mobile">
        <FlexColumn className="margin_right_16">
          <StyledLabel htmlFor="country">Country</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('country')}
            type="text"
            id="billing-country"
            placeholder="Country"
            name="country"
          />
        </FlexColumn>
        <FlexColumn>
          <StyledLabel htmlFor="postalCode">Zip Code</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('postalCode')}
            type="text"
            id="billing-zip"
            placeholder="Zip Code"
            name="postalCode"
          />
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
};
