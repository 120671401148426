import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  height: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    overflow: hidden;
    height: 100vh;
  }
`;

export const RightPane = styled.div`
  flex: 0 40%;
  padding: 40px;
  height: 100vh;
  overflow: scroll;
  background: #fff;
  @media screen and (min-width: 768px) {
    background: #f5f5f5;
  }
`;

export const LeftPane = styled.div`
  flex: 0 60%;
  padding: 64px;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  @media screen and (min-width: 768px) {
    background: #fff;
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  max-width: 550px;
`;

export const CopyContainer = styled.div<{ notWhiteMobile?: boolean }>`
  background-color: ${(props) =>
    props.notWhiteMobile ? '#f5f5f5' : '#ffffff'};
  padding: 16px;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 768px) {
    background-color: #f5f5f5;
    padding: 32px;
    min-width: 500px;
  }
`;
