import React from 'react';
import {
  FlexColumn,
  CheckboxLabel,
  H2,
  FlexRow,
  StyledCheckbox,
} from '../components';

interface Props {
  formik: any;
}

export const OgQuestions: React.FC<Props> = ({ formik }) => {
  return (
    <>
      <FlexColumn className="margin_bottom_32">
        <H2 className="margin_bottom_16">Do you have any food restrictions?</H2>
        <FlexRow>
          <CheckboxLabel htmlFor="vegetarian">Vegetarian</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="vegetarian"
            name="restrictions"
            value="Vegetarian"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="vegan">Vegan</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="vegan"
            name="restrictions"
            value="Vegan"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="gf">Gluten Free</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="gf"
            name="restrictions"
            value="Gluten Free"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="pescatarian">Pescatarian</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="pescatarian"
            name="restrictions"
            value="Pescatarian"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="other">Other</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="other"
            name="restrictions"
            value="Other"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="none">None</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="none"
            name="restrictions"
            value="None"
          />
        </FlexRow>
      </FlexColumn>
    </>
  );
};
