import React from 'react';
import {
  FlexColumn,
  CheckboxLabel,
  H2,
  StyledCheckbox,
  FlexRow,
} from '../components';
import { SelectRow } from './GuestQuestions.styles';

interface Props {
  formik: any;
}
export const LuxeQuestions: React.FC<Props> = ({ formik }) => {
  return (
    <>
      {/* <FlexColumn className="margin_bottom_16">
        <H2 className="margin_bottom_16">
          Which drink would you like for breakfast?
        </H2>
        <SelectRow>
          <CheckboxLabel htmlFor="cold-brew">Cold Brew</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('drink')}
            type="radio"
            id="cold-brew"
            name="drink"
            value="Cold Brew"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="hot-coffee">Hot Coffee</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('drink')}
            type="radio"
            id="hot-coffee"
            name="drink"
            value="Hot Coffee"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="decaf">Decaf Hot Coffee</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('drink')}
            type="radio"
            id="decaf"
            name="drink"
            value="Decaf"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="hot-tea">Hot Tea</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('drink')}
            type="radio"
            id="hot-tea"
            name="drink"
            value="Hot Tea"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="iced-tea">Iced Tea</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('drink')}
            type="radio"
            id="iced-tea"
            name="drink"
            value="Iced Tea"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="pass">I'll pass </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('drink')}
            type="radio"
            id="pass"
            name="drink"
            value="pass"
          />
        </SelectRow>
      </FlexColumn> */}
      {/* <FlexColumn className="margin_bottom_16">
        <H2 className="margin_bottom_16">
          Which would you like for breakfast?
        </H2>
        <SelectRow>
          <CheckboxLabel htmlFor="bagel">Bagel & cream cheese</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="bagel"
            name="pastry"
            value="Bagel & cream cheese"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="muffin">Muffin</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="muffin"
            name="pastry"
            value="Muffin"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="loaf">Loaf cake</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="loaf"
            name="pastry"
            value="Loaf Cake"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="Croissant">Croissant</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="Croissant"
            name="pastry"
            value="Croissant"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="Scone">Scone</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="Scone"
            name="pastry"
            value="Scone"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="gf">Gluten-free option</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="gf"
            name="pastry"
            value="GF"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="pass">I'll pass</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('pastry')}
            type="radio"
            id="pass"
            name="pastry"
            value="pass"
          />
        </SelectRow>
      </FlexColumn> */}
      <FlexColumn className="margin_bottom_16">
        <H2 className="margin_bottom_16">Which would you like for lunch?</H2>
        <SelectRow>
          <CheckboxLabel htmlFor="veggie-sand">
            Veggie Sandwich (roasted carrot, zucchini, onion, mushroom, sweet
            peppers, lettuce, tomato, alioli, cheese)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="veggie-sand"
            name="lunch"
            value="Veggie Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="vegan-sand">
            Vegan Sandwich (roasted carrot, zucchini, onion, mushroom, sweet
            peppers, lettuce, tomato, vegan pesto)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="vegan-sand"
            name="lunch"
            value="Vegan Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="sausage">
            Sausage al Pesto Sandwich (polish sausage, pesto, cheese, lettuce,
            tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="sausage"
            name="lunch"
            value="Sausage al Pesto"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="iberico">
            Ibérico Sandwich (spanish prosciutto, cheese, lettuce, tomato,
            alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="iberico"
            name="lunch"
            value="Ibérico Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="pato">
            Pato Confit Sandwich (duck confit, lettuce, tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="pato"
            name="lunch"
            value="Pato Confit"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="ribeye">
            Ribeye Sandwich (philly cut ribeye, cheese, lettuce, tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="ribeye"
            name="lunch"
            value="Ribeye"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="shrimp-sand">
            Shrimp Sandwich (garlic shrimp, lettuce, tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="shrimp-sand"
            name="lunch"
            value="Shrimp Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="shrimp-tostada">
            Gluten-free option - Shrimp tostada
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="shrimp-tostada"
            name="lunch"
            value="Shrimp Tostada"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="potato-tostada">
            Gluten-free option - Potato tostada
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="potato-tostada"
            name="lunch"
            value="Potato Tostada"
          />
        </SelectRow>
      </FlexColumn>
      <FlexColumn className="margin_bottom_32">
        <H2 className="margin_bottom_16">Do you have any food restrictions?</H2>
        <FlexRow>
          <CheckboxLabel htmlFor="vegetarian">Vegetarian</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="vegetarian"
            name="restrictions"
            value="Vegetarian"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="vegan">Vegan</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="vegan"
            name="restrictions"
            value="Vegan"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="gf">Gluten Free</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="gf"
            name="restrictions"
            value="Gluten Free"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="pescatarian">Pescatarian</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="pescatarian"
            name="restrictions"
            value="Pescatarian"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="other">Other</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="other"
            name="restrictions"
            value="Other"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="none">None</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('restrictions')}
            type="radio"
            id="none"
            name="restrictions"
            value="None"
          />
        </FlexRow>
      </FlexColumn>
      {/* <FlexColumn>
        <StyledLabelLarge className="margin_bottom_16" htmlFor="alcohol">
          What are your favorite beers/ hard seltzers? (to enjoy in the van in
          between wineries)
        </StyledLabelLarge>
        <StyledInput
          {...formik.getFieldProps('alcohol')}
          type="text"
          id="alcohol"
          placeholder="White Claw, Modelo, Topo Chico etc."
          name="alcohol"
        />
      </FlexColumn> */}
    </>
  );
};
