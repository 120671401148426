import React from 'react';
import { StyledInput, StyledLabel, FlexRow } from '../../../components';

interface SameAsPickupProps {
  formik: any;
  handleOnSameCheck: (e: any) => void;
}

export const SameAsPickup: React.FC<SameAsPickupProps> = ({
  formik,
  handleOnSameCheck,
}) => {
  return (
    <FlexRow>
      <StyledLabel style={{ fontSize: 14 }} htmlFor="isSame">
        Same as pickup address
      </StyledLabel>
      <StyledInput
        {...formik.getFieldProps('isSame')}
        type="checkbox"
        id="same-input"
        name="isSame"
        onChange={handleOnSameCheck}
      />
    </FlexRow>
  );
};
