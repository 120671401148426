import React from 'react';
import {
  FlexColumn,
  FlexRow,
  P,
  PInfo,
  StyledInput,
} from '../../../components';
import { Button } from '../../../components/Button.styles';
import { Item, Discount } from '../../CheckoutForms.controller';
import {
  ProductImage,
  ImageRow,
  StyledTitle,
  StyledSubtitle,
  UpgradeRow,
} from './ProductSummary.styles';
import { ReactComponent as DotLoader } from '../../../assets/dot-loader.svg';

interface ProductSummaryData {
  items: Array<Item>;
  totalAmount: number;
  discounts: Array<Discount>;
}
interface ProductSummaryProps {
  date: string;
  productSummary: ProductSummaryData;
  privateChecked: boolean;
  formik: any;
  onSubmit: () => void;
  discountData: any;
  loading: boolean;
}

export const ProductSummary: React.FC<ProductSummaryProps> = ({
  productSummary,
  date,
  privateChecked,
  formik,
  onSubmit,
  discountData,
  loading,
}) => {
  const upgradeToPrivate = productSummary?.items?.find((item) => {
    return item.name === 'Upgrade to Private';
  });
  const daycation = productSummary?.items?.find((item) => {
    return item.name !== 'Upgrade to Private';
  });

  const upgradeFiller = {
    amount: 20000,
    name: 'Upgrade to Private',
    quantity: '1',
  };

  const total =
    upgradeToPrivate && !privateChecked
      ? productSummary.totalAmount - upgradeToPrivate.amount
      : !upgradeToPrivate && privateChecked
      ? productSummary.totalAmount + upgradeFiller.amount
      : productSummary.totalAmount;

  const appliedDiscount = discountData?.applyDiscountToOrder?.appliedDiscount
    ? discountData.applyDiscountToOrder.appliedDiscount
    : 0;

  console.log(total, appliedDiscount);

  // const totalWithDiscount = discountData?.applyDiscountToOrder?.success
  //   ? total - discountData.applyDiscountToOrder.appliedDiscount
  //   : total;

  return (
    <FlexColumn>
      <ImageRow>
        <ImageRow>
          <ProductImage />
          <FlexColumn>
            <StyledTitle>{daycation?.name}</StyledTitle>
            <StyledSubtitle>{date}</StyledSubtitle>
          </FlexColumn>
        </ImageRow>
        {daycation?.amount && (
          <FlexRow className="row_flex_end">
            <P>{`${daycation?.quantity} guests x $${(
              daycation.amount / 100
            ).toLocaleString('en-US')}`}</P>
          </FlexRow>
        )}
      </ImageRow>
      <FlexColumn className="margin_bottom_16">
        <StyledInput
          {...formik.getFieldProps('code')}
          type="text"
          id="promo-code"
          placeholder="Discount code"
          name="code"
        />
        {discountData?.applyDiscountToOrder?.success === false && (
          <PInfo style={{ color: '#cc0000' }}>
            {discountData?.applyDiscountToOrder?.reason}
          </PInfo>
        )}
        <Button
          disabled={!formik.isValid || !formik.dirty || loading}
          className="margin_top_24"
          onClick={onSubmit}
        >
          {loading ? <DotLoader /> : 'Apply'}
        </Button>
      </FlexColumn>
      {upgradeToPrivate && privateChecked && (
        <UpgradeRow>
          <StyledTitle style={{ width: '100%' }}>
            {upgradeToPrivate.name}
          </StyledTitle>
          <FlexRow className="row_flex_end">
            <P>{`${upgradeToPrivate.quantity} x $${(
              upgradeToPrivate.amount / 100
            ).toLocaleString('en-US')}`}</P>
          </FlexRow>
        </UpgradeRow>
      )}
      {!upgradeToPrivate && privateChecked && (
        <UpgradeRow>
          <StyledTitle style={{ width: '100%' }}>
            {upgradeFiller.name}
          </StyledTitle>
          <FlexRow className="row_flex_end">
            <P>{`${upgradeFiller.quantity} x $${(
              upgradeFiller.amount / 100
            ).toLocaleString('en-US')}`}</P>
          </FlexRow>
        </UpgradeRow>
      )}
      {(discountData?.applyDiscountToOrder?.success ||
        productSummary?.discounts?.length) && (
        <UpgradeRow>
          <StyledTitle style={{ width: '100%' }}>WELCOME</StyledTitle>
          <FlexRow className="row_flex_end">
            <P>{`- $${(
              discountData?.applyDiscountToOrder?.appliedDiscount / 100 ||
              productSummary.discounts[0].amount / 100
            ).toLocaleString('en-US')}`}</P>
          </FlexRow>
        </UpgradeRow>
      )}
      <hr />
      <FlexRow className="space_between">
        <P>Subtotal</P>{' '}
        {discountData?.applyDiscountToOrder?.success ? (
          <P>${((total - appliedDiscount) / 100).toLocaleString('en-US')}</P>
        ) : (
          <P>${(total / 100).toLocaleString('en-US')}</P>
        )}
      </FlexRow>
      <hr />
      <FlexRow className="space_between">
        <P>Total</P>
        {discountData?.applyDiscountToOrder?.success ? (
          <P>${((total - appliedDiscount) / 100).toLocaleString('en-US')}</P>
        ) : (
          <P>${(total / 100).toLocaleString('en-US')}</P>
        )}
      </FlexRow>
      <FlexRow></FlexRow>
    </FlexColumn>
  );
};
