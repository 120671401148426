import { gql } from '@apollo/client';

export const GET_ORDER = gql`
  query GetDraftOrderById($id: String!) {
    getDraftOrderById(id: $id) {
      id
      startDate
      endDate
      totalAmount
      state
      canOptForPublic
      showUpgradeToPrivate
      minToOperate
      type
      discounts {
        name
        amount
      }
      items {
        name
        quantity
        amount
      }
      quantity
    }
  }
`;

export const GET_CALENDAR = gql`
  query GetAvailabilityByCalId($calendarId: String!) {
    getAvailabilityByCalId(calendarId: $calendarId) {
      minDate
      maxDate
      blockedDates
      publicDates
      availabilityDates
    }
  }
`;

export const GET_PRICING = gql`
  query GetPricing($id: String!) {
    getCatalogItemById(id: $id) {
      id
      prices {
        minQuantity
        maxQuantity
        price
        upgrade
      }
    }
  }
`;

export const GET_ORDER_TYPE_BY_ID = gql`
  query GetOrderType($id: String!) {
    getOrderTypeById(id: $id) {
      type
      docuSignOnly
      upgradedDining
    }
  }
`;
