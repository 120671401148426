import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column-reverse;
  height: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    overflow: hidden;
  }
`;

export const RightPane = styled.div`
  flex: 0 40%;
  background: #f5f5f5;
  padding: 32px;
`;

export const LeftPane = styled.div`
  flex: 0 60%;
  padding: 0 32px;
  @media screen and (min-width: 768px) {
    height: 100vh;
    overflow: scroll;
    padding: 64px;
  }
`;

export const Content = styled.div`
  max-width: 100%;
  position: relative;
  overflow-x: clip;
`;

export const SkipButton = styled.button`
  align-self: flex-end;
  width: 150px;
`;

export const NextGuestButton = styled.button`
  width: 150px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`;

export const LoaderBox = styled.div`
  width: 175px;
  height: 100%;
`;

export const ErrorBox = styled.div`
  max-width: 300px;
  min-width: 275px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  @media screen and (min-width: 768px) {
    max-width: 500px;
  }
`;

export const LogoBox = styled.div`
  width: 64px;
  position: relative;
  top: 24px;
  right: 100%;
  margin: 0 auto;
`;
