import React from 'react';
import { Container, Button, BackLink } from './ProgressFooter.styles';
import { ReactComponent as DotLoader } from '../../../assets/dot-loader.svg';

interface ProgressFooterProps {
  onNext: () => void;
  pageState: number;
  disabled: boolean;
  loading?: boolean;
}

export const ProgressFooter: React.FC<ProgressFooterProps> = ({
  onNext,
  disabled,
  loading,
}) => {
  const goBack = () => {
    window.history.back();
  };

  const getText = () => {
    // update this?
    return 'Continue';
    // switch (pageState) {
    //   case 0:
    //     return 'Continue to guest information';
    //   case 1:
    //     return 'Continue';
    //   case 2:
    //     return 'Continue to payment';
    // }
  };
  return (
    <Container>
      <BackLink onClick={goBack}>{`← Return home`}</BackLink>
      <Button disabled={disabled || loading} onClick={onNext}>
        {loading ? <DotLoader /> : getText()}
      </Button>
    </Container>
  );
};
