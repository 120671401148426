import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: 'brandon-grotesque', sans-serif;
  color: #333;
`;

export const H2 = styled.h2`
  font-family: 'brandon-grotesque', sans-serif;
  color: #333;
`;

export const H3 = styled.h3`
  font-family: 'brandon-grotesque', sans-serif;
  color: #333;
`;

export const H4 = styled.h4`
  font-family: 'Open Sans', sans-serif;
  color: #333;
`;

export const H5 = styled.h5`
  font-family: 'brandon-grotesque', sans-serif;
  color: #333;
`;

export const PInfo = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 20px;
`;

export const P = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 24px;
`;
