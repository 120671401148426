import React, { useRef, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { useEventCallback } from '../../utils/useEventCallback';
import { useIsomorphicLayoutEffect } from '../../utils/useIsomorphicLayoutEffect';
import { StyledContentTransitionBox } from './SlideInTransition.styles';

export const SlideInTransition: React.FC<{
  isIn: boolean;
  from: 'left' | 'right';
  enableHideOnOut?: boolean;
}> = ({ children, enableHideOnOut, isIn, from }) => {
  const directionMultiplier = from === 'left' ? -1 : 1;

  const timerRef = useRef<{ requestId: number }>({ requestId: 0 });

  const [{ opacity, x }, set] = useSpring<{ opacity: number; x: number }>(
    () => ({
      opacity: isIn ? 1 : 0,
      x: isIn ? 0 : 100,
    })
  );

  const reposition = useEventCallback(() => {
    timerRef.current.requestId = requestAnimationFrame(() => {
      const windowWidth = window.innerWidth || 0;
      set.start({ opacity: isIn ? 1 : 0, x: isIn ? 0 : windowWidth });
    });
  });

  const cancelRequests = useEventCallback(() => {
    const { requestId } = timerRef.current;
    window.cancelAnimationFrame(requestId);
  });

  useIsomorphicLayoutEffect(() => {
    reposition();
  }, [reposition, isIn]);

  useEffect(() => {
    return () => {
      cancelRequests();
    };
  }, [cancelRequests]);

  useEffect(() => {
    window.addEventListener?.('resize', reposition, false);
    return () => {
      window.removeEventListener?.('resize', reposition, false);
    };
  }, [reposition]);

  return (
    <StyledContentTransitionBox
      as={animated.div}
      style={{
        opacity: opacity,
        pointerEvents: opacity.to((o) => (o === 100 ? 'none' : 'initial')),
        transform: x.to(
          (x) => `translate3d(${x * directionMultiplier}px, 0, 0)`
        ),
        display: enableHideOnOut
          ? opacity.to((o) => (o === 0 ? 'none' : 'block'))
          : undefined,
        visibility: opacity.to((o) => (o === 0 ? 'hidden' : 'visible')),
      }}
    >
      {children}
    </StyledContentTransitionBox>
  );
};
