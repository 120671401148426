import React from 'react';
import {
  FlexColumn,
  CheckboxLabel,
  H2,
  StyledCheckbox,
  FlexRow,
} from '../components';
import { SelectRow } from './GuestQuestions.styles';

interface Props {
  formik: any;
}

export const BudgetQuestions: React.FC<Props> = ({ formik }) => {
  return (
    <>
      <FlexColumn className="margin_bottom_16">
        <H2 className="margin_bottom_16">Which would you like for dinner?</H2>
        <SelectRow>
          <CheckboxLabel htmlFor="veggie-sand">
            Veggie Sandwich (roasted carrot, zucchini, onion, mushroom, sweet
            peppers, lettuce, tomato, alioli, cheese)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="veggie-sand"
            name="lunch"
            value="Veggie Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="vegan-sand">
            Vegan Sandwich (roasted carrot, zucchini, onion, mushroom, sweet
            peppers, lettuce, tomato, vegan pesto)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="vegan-sand"
            name="lunch"
            value="Vegan Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="sausage">
            Sausage al Pesto Sandwich (polish sausage, pesto, cheese, lettuce,
            tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="sausage"
            name="lunch"
            value="Sausage al Pesto"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="iberico">
            Ibérico Sandwich (spanish prosciutto, cheese, lettuce, tomato,
            alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="iberico"
            name="lunch"
            value="Ibérico Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="pato">
            Pato Confit Sandwich (duck confit, lettuce, tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="pato"
            name="lunch"
            value="Pato Confit"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="ribeye">
            Ribeye Sandwich (philly cut ribeye, cheese, lettuce, tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="ribeye"
            name="lunch"
            value="Ribeye"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="shrimp-sand">
            Shrimp Sandwich (garlic shrimp, lettuce, tomato, alioli)
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="shrimp-sand"
            name="lunch"
            value="Shrimp Sandwich"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="shrimp-tostada">
            Gluten-free option - Shrimp tostada
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="shrimp-tostada"
            name="lunch"
            value="Shrimp Tostada"
          />
        </SelectRow>
        <SelectRow>
          <CheckboxLabel htmlFor="potato-tostada">
            Gluten-free option - Potato tostada
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('lunch')}
            type="radio"
            id="potato-tostada"
            name="lunch"
            value="Potato Tostada"
          />
        </SelectRow>
      </FlexColumn>
      <FlexColumn className="margin_bottom_32">
        <H2 className="margin_bottom_16">Which are your go-to tacos?</H2>
        <FlexRow>
          <CheckboxLabel htmlFor="shrimp">Shrimp</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('shrimp')}
            type="checkbox"
            id="shrimp"
            name="shrimp"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="fish">Fish</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('fish')}
            type="checkbox"
            id="fish"
            name="fish"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="carneAsada">Carne Asada</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('carneAsada')}
            type="checkbox"
            id="carneAsada"
            name="carneAsada"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="alPastor">Al Pastor</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('alPastor')}
            type="checkbox"
            id="alPastor"
            name="alPastor"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="quesadilla">Quesadillas</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('quesadilla')}
            type="checkbox"
            id="quesadilla"
            name="quesadilla"
          />
        </FlexRow>
        <FlexRow>
          <CheckboxLabel htmlFor="imvegan">I'm Vegan</CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('imvegan')}
            type="checkbox"
            id="imvegan"
            name="imvegan"
          />
        </FlexRow>
      </FlexColumn>
    </>
  );
};
