import React from 'react';
import {
  FlexColumn,
  StyledInput,
  StyledLabel,
  FlexRow,
  StyledTextArea,
  CheckboxLabel,
  StyledCheckbox,
  H4,
  P,
} from '../../../components';
import Input from 'react-phone-number-input/input';

interface PrimaryGuestFormProps {
  formik: any;
  handlePhoneChange: (e: any) => void;
  phoneVal: string;
  showOptForPublic: boolean;
  isBudget?: boolean;
  showUpgradeToPrivate?: boolean;
  qty: number;
  minToOperate: number;
}

export const PrimaryGuestForm: React.FC<PrimaryGuestFormProps> = ({
  formik,
  handlePhoneChange,
  phoneVal,
  isBudget,
  qty,
  minToOperate,
}) => {
  return (
    <FlexColumn>
      {/* {showOptForPublic && (
        <FlexColumn className="margin_bottom_24">
          <H4 className="margin_bottom_24">Make it public?</H4>
          <FlexRow style={{ maxWidth: '300px' }}>
            <CheckboxLabel htmlFor="makePublic" style={{ fontWeight: 600 }}>
              Allow others to join my trip
            </CheckboxLabel>
            <StyledCheckbox
              {...formik.getFieldProps('makePublic')}
              type="checkbox"
              id="same-input"
              name="makePublic"
            />
          </FlexRow>
          <P className="margin_bottom_16 margin_top_0">
            If you opt to let others join your trip, we will refund you the
            difference for whichever price tier your group falls into if others
            book your date. For example, if you are a group of 4 and 2 more
            join, you will be refunded $110 per person.
          </P>
        </FlexColumn>
      )} */}
      {/* {!isBudget && (
        <FlexRow className="margin_bottom_24 margin_top_24">
          <CheckboxLabel
            htmlFor="decreaseTerms"
            style={{ maxWidth: '500px', marginRight: '24px' }}
          >
            I understand that if my group size decreases, the price per person
            may increase if my new group size falls into a different price tier.
          </CheckboxLabel>
          <StyledCheckbox
            {...formik.getFieldProps('decreaseTerms')}
            type="checkbox"
            id="same-input"
            name="decreaseTerms"
          />
        </FlexRow>
      )} */}
      {isBudget && (
        <FlexColumn className="margin_top_24">
          <FlexRow className="margin_bottom_24">
            <CheckboxLabel
              htmlFor="groupTerms"
              style={{ maxWidth: '500px', marginRight: '24px' }}
            >
              {`I understand that if my date does not reach the minimum of ${minToOperate}
              guests within 72 hours of departure, the trip will be canceled and
              I will be refunded or I can opt for a private tour at a different
              price.`}
            </CheckboxLabel>
            <StyledCheckbox
              {...formik.getFieldProps('groupTerms')}
              type="checkbox"
              id="same-input"
              name="groupTerms"
            />
          </FlexRow>
          {/* {showUpgradeToPrivate && (
            <FlexColumn className="margin_bottom_24">
              <FlexRow>
                <CheckboxLabel
                  htmlFor="upgradeBudgetToPrivate"
                  style={{
                    fontWeight: 600,
                    maxWidth: '500px',
                    marginRight: '24px',
                  }}
                >
                  I want to make this trip private for $200 so no one else can
                  join
                </CheckboxLabel>
                <StyledCheckbox
                  {...formik.getFieldProps('upgradeBudgetToPrivate')}
                  type="checkbox"
                  id="same-input"
                  name="upgradeBudgetToPrivate"
                />
              </FlexRow>
            </FlexColumn>
          )} */}
          {qty === 13 && (
            <P className="margin_top_0">
              Congrats! You've filled the van. Your tour will be private!
            </P>
          )}
        </FlexColumn>
      )}
      <H4>Customer Information</H4>
      <FlexRow className="margin_bottom_24 stack_mobile">
        <FlexColumn className="margin_right_16">
          <StyledLabel htmlFor="firstName">First Name</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('firstName')}
            type="text"
            id="name-input"
            placeholder="First Name"
            name="firstName"
          />
        </FlexColumn>
        <FlexColumn>
          <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('lastName')}
            type="text"
            id="name-input"
            placeholder="Last Name"
            name="lastName"
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow className="stack_mobile margin_bottom_16">
        <FlexColumn className="margin_right_16">
          <StyledLabel htmlFor="email">Email</StyledLabel>
          <StyledInput
            {...formik.getFieldProps('email')}
            type="text"
            id="name-input"
            placeholder="email@example.com"
            name="email"
          />
        </FlexColumn>
        <FlexColumn>
          <StyledLabel htmlFor="phoneNumber">Phone Number</StyledLabel>
          <Input
            country="US"
            type="text"
            id="name-input"
            name="phoneNumber"
            placeholder="(555) 555-555"
            value={phoneVal}
            onChange={handlePhoneChange}
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <FlexColumn>
          <StyledLabel htmlFor="notes">Notes</StyledLabel>
          <StyledTextArea
            {...formik.getFieldProps('notes')}
            type="text"
            id="notes-input"
            placeholder="Anything we should know? Food restrictions, surprise trip, bachelorette party etc."
            name="notes"
          />
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
};
