import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { FlexRow, H1, H2, H3, P, PInfo } from '../components';
import {
  TextContainer,
  CopyContainer,
} from '../confirmation-questions/ConfirmationQuestions.styles';
import { Button } from '../components/Button.styles';
// import { useConfirmationQuestionsController } from '../confirmation-questions/ConfirmationQuestions.controller';
import { ReactComponent as DotLoader } from '../assets/dot-loader.svg';
import { ErrorBox, LoaderContainer } from '../checkout/Checkout.styles';
import copy from 'copy-to-clipboard';

export const ConfirmationQuestions = () => {
  let { referenceId } = useParams();
  const parsed = queryString.parse(window.location.search);
  const [copying, setCopying] = useState(false);
  // const { formResponse, reload } = useConfirmationQuestionsController({
  //   orderId: parsed.orderId,
  // });

  if (!parsed.orderId) {
    window.location.replace('https://www.ruadeseo.com');
  }

  const copyOnClick = () => {
    setCopying(true);
    copy(String(parsed.guestUrl));
    setTimeout(() => {
      setCopying(false);
    }, 300);
  };

  return (
    <LoaderContainer>
      <ErrorBox>
        <TextContainer className="margin_bottom_32">
          <H1 className="margin_bottom_0">Order confirmed!</H1>
          <PInfo className="margin_top_0">{`#${referenceId}`}</PInfo>
          <H3>
            Thank you for booking with Ruadeseo! We cannot wait to experience
            Baja California with you. You will receive a confirmation email
            shortly.
          </H3>
          <P>
            In the meantime, please answer these questions so we can make sure
            your group has the best experience possible!
          </P>
        </TextContainer>
        <CopyContainer notWhiteMobile={true}>
          <H2 className="margin_bottom_0">
            Please send this link to all guests!
          </H2>
          <P className="margin_top_0">(We also sent it to your email)</P>
          <FlexRow
            className="margin_bottom_16"
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <PInfo
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '200px',
                display: 'block',
                overflow: 'hidden',
              }}
            >
              {parsed.guestUrl}
            </PInfo>
            <Button
              style={{
                width: '100px',
                height: '40px',
                padding: '0px',
                borderRadius: '5px',
              }}
              onClick={copyOnClick}
            >
              {copying ? <DotLoader /> : 'Copy'}
            </Button>
          </FlexRow>
          <H3>
            And fill it out for yourself{' '}
            <a
              target="__blank"
              href={decodeURI(parsed.guestUrl as string) || ''}
            >
              here
            </a>
            !
          </H3>
        </CopyContainer>
      </ErrorBox>
    </LoaderContainer>
  );

  // return (
  //   <Container>
  //     <LeftPane>
  //       <TextContainer className="margin_bottom_32">
  //         <H1 className="margin_bottom_0">Order confirmed!</H1>
  //         <PInfo className="margin_top_0">{`#${referenceId}`}</PInfo>
  //         <H3>
  //           Thank you for booking with Ruadeseo! We cannot wait to experience
  //           Baja California with you. You will receive a confirmation email
  //           shortly.
  //         </H3>
  //         <P>
  //           In the meantime, please answer these questions so we can make sure
  //           your group has the best experience possible!
  //         </P>
  //       </TextContainer>
  //       <CopyContainer>
  //         <H2 className="margin_bottom_0 text_align_center">
  //           Please send this link to all guests!
  //         </H2>
  //         <P className="margin_top_0 text_align_center">
  //           (We also sent it to your email)
  //         </P>
  //         <FlexRow
  //           className="margin_bottom_16"
  //           style={{ alignItems: 'center', justifyContent: 'center' }}
  //         >
  //           <PInfo
  //             style={{
  //               whiteSpace: 'nowrap',
  //               textOverflow: 'ellipsis',
  //               width: '200px',
  //               display: 'block',
  //               overflow: 'hidden',
  //             }}
  //           >
  //             {parsed.guestUrl}
  //           </PInfo>
  //           <Button
  //             style={{
  //               width: '100px',
  //               height: '40px',
  //               padding: '0px',
  //               borderRadius: '5px',
  //             }}
  //             onClick={copyOnClick}
  //           >
  //             {copying ? <DotLoader /> : 'Copy'}
  //           </Button>
  //         </FlexRow>
  //         <H3 className="text_align_center">
  //           And fill it out for yourself{' '}
  //           <a
  //             target="__blank"
  //             href={decodeURI(parsed.guestUrl as string) || ''}
  //           >
  //             here
  //           </a>
  //           !
  //         </H3>
  //       </CopyContainer>
  //     </LeftPane>
  //     <RightPane className="margin_bottom_48">
  //       <H1 className="margin_bottom_0">Trip Questions</H1>
  //       <FlexColumn>
  //         <H2 className="margin_bottom_0">
  //           What is your desired winery experience?
  //         </H2>
  //         <PInfo className="margin_top_0 margin_bottom_24">
  //           Please check all that apply.
  //         </PInfo>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="greatWine">
  //             {nameMappings['greatWine']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...vibeQuestionsFormik.getFieldProps('greatWine')}
  //             type="checkbox"
  //             id="same-input"
  //             name="greatWine"
  //           />
  //         </FlexRow>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="greatViews">
  //             {nameMappings['greatViews']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...vibeQuestionsFormik.getFieldProps('greatViews')}
  //             type="checkbox"
  //             id="same-input"
  //             name="greatViews"
  //           />
  //         </FlexRow>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="learn">
  //             {nameMappings['learn']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...vibeQuestionsFormik.getFieldProps('learn')}
  //             type="checkbox"
  //             id="same-input"
  //             name="learn"
  //           />
  //         </FlexRow>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="party">
  //             {nameMappings['party']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...vibeQuestionsFormik.getFieldProps('party')}
  //             type="checkbox"
  //             id="same-input"
  //             name="party"
  //           />
  //         </FlexRow>
  //       </FlexColumn>
  //       <FlexColumn className="margin_bottom_32">
  //         <H2 className="margin_bottom_0">
  //           What is your restaurant preference?
  //         </H2>
  //         <PInfo className="margin_top_0 margin_bottom_24">
  //           We will do our best to book your restaurant of choice. Some can be
  //           challenging to book without enough notice.
  //         </PInfo>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="fincaAltozano">
  //             {nameMappings['fincaAltozano']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...restaurantQuestionsFormik.getFieldProps('fincaAltozano')}
  //             type="checkbox"
  //             id="same-input"
  //             name="fincaAltozano"
  //           />
  //         </FlexRow>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="brumaWineGarden">
  //             {nameMappings['brumaWineGarden']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...restaurantQuestionsFormik.getFieldProps('brumaWineGarden')}
  //             type="checkbox"
  //             id="same-input"
  //             name="brumaWineGarden"
  //           />
  //         </FlexRow>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="fauna">
  //             {nameMappings['fauna']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...restaurantQuestionsFormik.getFieldProps('fauna')}
  //             type="checkbox"
  //             name="fauna"
  //           />
  //         </FlexRow>
  //         <FlexRow>
  //           <CheckboxLabel htmlFor="animalon">
  //             {nameMappings['animalon']}
  //           </CheckboxLabel>
  //           <StyledCheckbox
  //             {...restaurantQuestionsFormik.getFieldProps('animalon')}
  //             type="checkbox"
  //             id="animalon-input"
  //             name="animalon"
  //           />
  //         </FlexRow>
  //       </FlexColumn>
  //       {formResponse?.answerTripQuestions?.succes === false ? (
  //         <P>
  //           Error: sorry we are experiencing an unexpected problem, we will
  //           reach out via email!
  //         </P>
  //       ) : null}
  //       <Button onClick={onSubmit} disabled={formLoading}>
  //         {formLoading ? <DotLoader /> : 'Submit'}
  //       </Button>
  //     </RightPane>
  //   </Container>
  // );
};
