import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_OPEN_ORDER } from '../graphql/mutations';
import { useState } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { ReactComponent as DotLoader } from '../assets/dot-loader.svg';

export interface Item {
  name: string;
  amount: number;
  quantity: string;
}

export interface Discount {
  name: string;
  amount: number;
}

interface ProductSummary {
  items: Array<Item>;
  totalAmount: number;
  discounts: Array<Discount>;
}

export const useCheckoutFormController = ({
  onNext,
  id,
  items,
  totalAmount,
  discounts,
}: {
  onNext: (hasUpgradedPrivate?: boolean) => void;
  id: any;
  items: Array<Item>;
  totalAmount: number;
  discounts: Array<Discount>;
}) => {
  const [phoneVal, setPhoneVal] = useState('');
  const [googleLoading, setGoogleLoading] = useState(false);
  const [googlePlace, setGooglePlace] = useState<{
    label: any;
    value: any;
  } | null>(null);
  const [googlePlaceBilling, setGooglePlaceBilling] = useState<{
    label: any;
    value: any;
  } | null>(null);

  const [productSummary, setProductSummary] = useState<ProductSummary>({
    items: [],
    totalAmount: 0,
    discounts: [],
  });

  const [
    createOpenOrder,
    { loading: openOrderLoading, error: openOrderError },
  ] = useMutation(CREATE_OPEN_ORDER);

  const billingSameAsPickupFormik = useFormik({
    initialValues: {
      isSame: false,
    },
    validationSchema: Yup.object<any>({
      isSame: Yup.boolean(),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (items?.length) {
      setProductSummary({
        items,
        totalAmount,
        discounts,
      });
    }
  }, [items, totalAmount, discounts]);

  const primaryGuestFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      notes: '',
      makePublic: false,
      groupTerms: false,
      decreaseTerms: false,
      upgradeBudgetToPrivate: false,
    },
    validationSchema: Yup.object<any>({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      phoneNumber: Yup.string().required(),
      email: Yup.string()
        .email()
        .required()
        .transform((value) => value.trim()),
      notes: Yup.string(),
      makePublic: Yup.boolean(),
      groupTerms: Yup.boolean(),
      decreaseTerms: Yup.boolean(),
      upgradeBudgetToPrivate: Yup.boolean(),
    }),

    onSubmit: () => {},
  });

  const termsFormik = useFormik({
    initialValues: {
      tosAccept: false,
    },
    validationSchema: Yup.object<any>({
      tosAccept: Yup.boolean().required(),
    }),
    onSubmit: () => {},
  });

  const discountFormik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object<any>({
      code: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const handlePhoneChange = (phoneNumber: string) => {
    if (!phoneNumber?.length) {
      return;
    }
    primaryGuestFormik.setFieldValue('phoneNumber', phoneNumber);
    setPhoneVal(phoneNumber);
  };

  const guestFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object<any>({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().email().required(),
    }),
    onSubmit: () => {},
  });

  const pickupAddressFormik = useFormik({
    initialValues: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    validationSchema: Yup.object<any>({
      addressLine1: Yup.string().required(),
      addressLine2: Yup.string(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      postalCode: Yup.string().required(),
      country: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const handleAddressSelect = async (selected: any) => {
    pickupAddressFormik.resetForm();
    const { terms, place_id } = selected.value;
    setGoogleLoading(true);
    setGooglePlace({
      label: <DotLoader />,
      value: '',
    });
    const [place] = await geocodeByPlaceId(place_id);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    const addressLine1 = `${terms[0].value} ${terms[1].value}`;
    pickupAddressFormik.setFieldValue('addressLine1', addressLine1);
    setGooglePlace({
      label: addressLine1,
      value: { description: addressLine1 },
    });
    setGoogleLoading(false);
    pickupAddressFormik.setFieldValue('addressLine2', '');
    pickupAddressFormik.setFieldValue('city', terms[2].value);
    pickupAddressFormik.setFieldValue('state', terms[3].value);
    pickupAddressFormik.setFieldValue('postalCode', postalCode);
    pickupAddressFormik.setFieldValue('country', terms[4].value);
  };

  const handleBillingAddressSelect = async (selected: any) => {
    billingAddressFormik.setFieldValue('addressLine1', '');
    billingAddressFormik.setFieldValue('addressLine2', '');
    billingAddressFormik.setFieldValue('city', '');
    billingAddressFormik.setFieldValue('state', '');
    billingAddressFormik.setFieldValue('postalCode', '');
    billingAddressFormik.setFieldValue('country', '');

    const { terms, place_id } = selected.value;
    setGoogleLoading(true);
    setGooglePlaceBilling({
      label: <DotLoader />,
      value: '',
    });
    const [place] = await geocodeByPlaceId(place_id);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    const addressLine1 = `${terms[0].value} ${terms[1].value}`;
    billingAddressFormik.setFieldValue('addressLine1', addressLine1);
    setGooglePlaceBilling({
      label: addressLine1,
      value: { description: addressLine1 },
    });
    setGoogleLoading(false);
    billingAddressFormik.setFieldValue('addressLine2', '');
    billingAddressFormik.setFieldValue('city', terms[2].value);
    billingAddressFormik.setFieldValue('state', terms[3].value);
    billingAddressFormik.setFieldValue('postalCode', postalCode);
    billingAddressFormik.setFieldValue('country', terms[4].value);
  };

  const handleOnSameCheck = (e: any) => {
    billingSameAsPickupFormik.setFieldValue('isSame', e.currentTarget.checked);
    if (e.currentTarget.checked) {
      billingAddressFormik.setFieldValue(
        'addressLine1',
        pickupAddressFormik.values.addressLine1
      );
      setGooglePlaceBilling({
        value: { description: pickupAddressFormik.values.addressLine1 },
        label: pickupAddressFormik.values.addressLine1,
      });
      billingAddressFormik.setFieldValue(
        'addressLine2',
        pickupAddressFormik.values.addressLine2
      );
      billingAddressFormik.setFieldValue(
        'city',
        pickupAddressFormik.values.city
      );
      billingAddressFormik.setFieldValue(
        'state',
        pickupAddressFormik.values.state
      );
      billingAddressFormik.setFieldValue(
        'postalCode',
        pickupAddressFormik.values.postalCode
      );
      billingAddressFormik.setFieldValue(
        'country',
        pickupAddressFormik.values.country
      );
    } else {
      billingAddressFormik.resetForm();
      setGooglePlaceBilling(null);
    }
  };

  const billingAddressFormik = useFormik({
    initialValues: {
      cardholderName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    validationSchema: Yup.object<any>({
      cardholderName: Yup.string().required(),
      addressLine1: Yup.string().required(),
      addressLine2: Yup.string(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      postalCode: Yup.string().required(),
      country: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const onCreateOpenOrder = async () => {
    const { data } = await createOpenOrder({
      variables: {
        createOpenOrderInput: {
          draftId: id,
          upgradeBudgetToPrivate:
            primaryGuestFormik.values.upgradeBudgetToPrivate,
          primaryGuest: {
            firstName: primaryGuestFormik.values.firstName,
            lastName: primaryGuestFormik.values.lastName,
            email: primaryGuestFormik.values.email.trim().toLocaleLowerCase(),
            phoneNumber: primaryGuestFormik.values.phoneNumber,
          },
          pickupAddress: {
            addressLine1:
              pickupAddressFormik.values.addressLine1 || 'Park Blvd',
            addressLine2: pickupAddressFormik.values.addressLine2,
            city: pickupAddressFormik.values.city || 'San Diego',
            state: pickupAddressFormik.values.state || 'CA',
            postalCode: pickupAddressFormik.values.postalCode || '92101',
            country: pickupAddressFormik.values.country,
          },
          optedForPublic: primaryGuestFormik.values.makePublic,
          guests: undefined,
          notes: primaryGuestFormik.values.notes
            ? primaryGuestFormik.values.notes
            : 'N/A',
        },
      },
    });
    if (data) {
      setProductSummary({
        items: data.createOpenOrder?.items,
        totalAmount: data.createOpenOrder?.totalAmountPayable,
        discounts: data.createOpenOrder?.discounts,
      });
    }
    onNext();
  };

  return {
    primaryGuestFormik,
    guestFormik,
    pickupAddressFormik,
    billingAddressFormik,
    billingSameAsPickupFormik,
    handleOnSameCheck,
    onCreateOpenOrder,
    handlePhoneChange,
    phoneVal,
    setPhoneVal,
    googlePlace,
    handleAddressSelect,
    setGooglePlace,
    handleBillingAddressSelect,
    googleLoading,
    openOrderError,
    openOrderLoading,
    googlePlaceBilling,
    productSummary,
    termsFormik,
    discountFormik,
  };
};
