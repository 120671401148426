import styled from 'styled-components';

export const BreadCrumbButton = styled.button<{ isActive: boolean }>`
  background-color: transparent;
  color: ${(props) => (props.isActive ? '#000' : '#333')};
  padding: 0;
  margin: 0;
  font-size: 12px;
  margin-right: 4px;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  :disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.3);
  }
  :active {
    background-color: transparent;
  }
  font-family: 'Open Sans', sans-serif;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  width: 275px;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    width: 100%;
    flex-wrap: nowrap;
  }
`;
