import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Checkout } from './routes/checkout';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';

import { ConfirmationQuestions } from './routes/confirmation-questions';
import { GuestQuestions } from './guest-questions/GuestQuestions';
import {
  Container,
  LoaderBox,
  LoaderContainer,
} from './calendar/Calendar.styles';
import { ReactComponent as Loader } from './assets/logo.svg';

const client = new ApolloClient({
  uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://ruadeseo.herokuapp.com',
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route
            path="checkout"
            element={
              <>
                <Helmet>
                  <script
                    type="text/javascript"
                    src="https://ruadeseo.s3.us-west-1.amazonaws.com/fullstory-noiframe.js"
                  ></script>
                </Helmet>
                <Checkout />
              </>
            }
          />
          <Route
            path="calendar"
            element={
              <>
                <Helmet>
                  <script
                    type="text/javascript"
                    src="https://ruadeseo.s3.us-west-1.amazonaws.com/fullstory.js"
                  ></script>
                </Helmet>
                <Container>
                  <LoaderContainer>
                    <LoaderBox>
                      <Loader />
                    </LoaderBox>
                  </LoaderContainer>
                </Container>
              </>
            }
          />
          <Route
            path="group"
            element={
              <>
                <Helmet>
                  <script
                    type="text/javascript"
                    src="https://ruadeseo.s3.us-west-1.amazonaws.com/fullstory.js"
                  ></script>
                </Helmet>
                <Container>
                  <LoaderContainer>
                    <LoaderBox>
                      <Loader />
                    </LoaderBox>
                  </LoaderContainer>
                </Container>
              </>
            }
          />
          <Route
            path="order-confirmation/:referenceId"
            element={<ConfirmationQuestions />}
          />
          <Route path="guest-questions/:orderId" element={<GuestQuestions />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
