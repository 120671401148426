import { useRef, useState } from 'react';

type PageState = 'customerInfo' | 'pickupAddress' | 'payment';

export const useCheckoutFlowController = (isBudget: boolean, qty: number) => {
  const pageOrder: PageState[] = ['customerInfo', 'pickupAddress', 'payment'];
  const [pageState, setPageState] = useState<number>(0);
  const [highestCompletedStep, setHighestCompletedStep] = useState<number>(0);

  const topRef = useRef<null | HTMLDivElement>(null);
  const executeScroll = () =>
    topRef?.current?.scrollIntoView({ behavior: 'smooth' });

  const onNext = (hasUpgradedToPrivate?: boolean) => {
    if (pageState + 1 > highestCompletedStep) {
      setHighestCompletedStep(pageState + 1);
    }

    if (isBudget) {
      if (pageState === 0 && !hasUpgradedToPrivate && qty !== 13) {
        setPageState(pageState + 2);
      } else {
        setPageState(pageState + 1);
      }
    } else {
      setPageState(pageState + 1);
    }

    executeScroll();
  };

  const onGoToCrumb = (e: any) => {
    const id = +e.currentTarget.dataset.item;
    setPageState(id);
    setHighestCompletedStep(id);
  };

  return {
    pageState,
    pageOrder,
    onNext,
    onGoToCrumb,
    highestCompletedStep,
    topRef,
  };
};
