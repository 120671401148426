import React from 'react';
import {
  FlexRow,
  H1,
  H3,
  P,
  H2,
  FlexColumn,
  StyledLabel,
  StyledInput,
} from '../components';
import { Container, FormWrapper } from './GuestQuestions.styles';
import {
  ErrorBox,
  LoaderBox,
  LoaderContainer,
} from '../checkout/Checkout.styles';
import { useGuestQuestionsController } from './GuestQuestions.controller';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Loader } from '../assets/logo.svg';

import { BudgetQuestions } from './BudgetQuestions';
import { OgQuestions } from './OgQuestions';
import { LuxeQuestions } from './LuxeQuestions';
import { Button } from '../components/Button.styles';
import { ReactComponent as DotLoader } from '../assets/dot-loader.svg';
import docusignEx from '../assets/docusign-example.jpg';

export const GuestQuestions = () => {
  const {
    luxeFormik,
    guestFormik,
    budgetFormik,
    docuSignFormik,
    reload,
    data,
    error,
    loading,
    guestData,
    guestError,
    guestLoading,
    orderId,
    addGuest,
  } = useGuestQuestionsController();

  if (!orderId) {
    window.location.replace('https://www.ruadeseo.com');
  }

  if (loading) {
    return (
      <Container>
        <LoaderContainer>
          <LoaderBox>
            <Loader />
          </LoaderBox>
        </LoaderContainer>
      </Container>
    );
  }

  if (guestError || error) {
    return (
      <LoaderContainer>
        <ErrorBox>
          <H1>Hmmm let's try that again...</H1>
          <H3>
            Please click{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={reload}
            >
              here
            </span>{' '}
            to try again
          </H3>
          <P>
            If you are still experiencing issues, please email us at
            info@ruadeseo.com or text/call (858) 704-6525
          </P>
        </ErrorBox>
      </LoaderContainer>
    );
  }

  if (guestData?.addGuest?.success) {
    return (
      <LoaderContainer>
        <ErrorBox>
          <H1>Thanks!</H1>
          <FlexColumn className="margin_bottom_24">
            <H3 className="margin_bottom_16">
              Please check your inbox for our release of liability waiver. It
              needs to be signed before departure!
            </H3>
            <img alt="docusign" src={docusignEx} />
          </FlexColumn>
          <H3>
            Please click{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={reload}
            >
              here
            </span>{' '}
            if you need to update your response.
          </H3>
          <P>
            If you are experiencing any issues, please email us at
            info@ruadeseo.com or text/call (858) 704-6525
          </P>
        </ErrorBox>
      </LoaderContainer>
    );
  }

  let formikInUse: any;
  const type = data?.getOrderTypeById.type;
  const docuSignOnly = data?.getOrderTypeById.docuSignOnly;
  const upgradedDining = data?.getOrderTypeById.upgradedDining;

  const onSubmit = () => {
    const lunch = [];
    if (type === 'og') {
      if (budgetFormik.values.alPastor) {
        lunch.push('Al Pastor');
      }
      if (budgetFormik.values.carneAsada) {
        lunch.push('Carne Asada');
      }
      if (budgetFormik.values.shrimp) {
        lunch.push('Shrimp');
      }
      if (budgetFormik.values.fish) {
        lunch.push('Fish');
      }
      if (budgetFormik.values.imvegan) {
        lunch.push(`I'm Vegan`);
      }
      if (budgetFormik.values.quesadilla) {
        lunch.push('Quesadillas');
      }
    }
    addGuest({
      variables: {
        guestInput: {
          orderId,
          firstName: guestFormik.values.firstName.trim(),
          lastName: guestFormik.values.lastName.trim(),
          email: guestFormik.values.email.trim(),
          lunch: lunch.length ? lunch.join(', ') : formikInUse.values?.lunch,
          drink: formikInUse.values?.drink,
          alcohol: formikInUse.values?.alcohol,
          pastry: formikInUse.values?.pastry,
          restriction: formikInUse.values?.restrictions,
        },
      },
    });
  };

  const renderForm = () => {
    if (docuSignOnly) {
      formikInUse = docuSignFormik;
      return <OgQuestions formik={docuSignFormik} />;
    } else if (type === 'budget' || (type === 'luxe' && !upgradedDining)) {
      formikInUse = budgetFormik;
      return <BudgetQuestions formik={budgetFormik} />;
    } else if (type === 'luxe') {
      formikInUse = luxeFormik;
      return <LuxeQuestions formik={luxeFormik} />;
    } else {
      return null;
    }
  };

  return (
    <Container>
      <FlexRow style={{ width: '50px', margin: '0 auto' }}>
        <Logo />
      </FlexRow>
      <FormWrapper>
        <FlexColumn className="margin_bottom_24">
          <H2 className="margin_bottom_16">Guest Information</H2>
          <FlexRow className="margin_bottom_16 stack_mobile">
            <FlexColumn className="margin_right_16">
              <StyledLabel htmlFor="firstName">First Name</StyledLabel>
              <StyledInput
                {...guestFormik.getFieldProps('firstName')}
                type="text"
                id="name-input"
                placeholder="First Name"
                name="firstName"
              />
            </FlexColumn>
            <FlexColumn>
              <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
              <StyledInput
                {...guestFormik.getFieldProps('lastName')}
                type="text"
                id="name-input"
                placeholder="Last Name"
                name="lastName"
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <StyledLabel htmlFor="email">Email</StyledLabel>
              <StyledInput
                {...guestFormik.getFieldProps('email')}
                type="text"
                id="name-input"
                name="email"
                placeholder="email@example.com"
              />
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        {renderForm()}
        <Button
          onClick={onSubmit}
          disabled={
            !formikInUse?.isValid ||
            !guestFormik.isValid ||
            guestLoading ||
            !guestFormik.dirty
          }
          className="margin_top_24"
        >
          {guestLoading ? <DotLoader /> : 'Submit'}
        </Button>
      </FormWrapper>
    </Container>
  );
};
