import styled from 'styled-components';

export const StyledInput = styled.input`
  :focus {
    border: solid 1px #333;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  font-family: 'Open Sans', sans-serif;
  resize: none;
  padding: 12px;
  border-radius: 5px;
  border-width: 1px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  :focus {
    border: solid 1px #333;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  /* :focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
  } */
  :-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

export const StyledCheckbox = styled.input`
  width: 18px;
  height: 18px;
`;
