import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import {
  Container,
  LeftPane,
  RightPane,
  Content,
  LoaderContainer,
  LoaderBox,
  ErrorBox,
} from '../checkout/Checkout.styles';
import {
  BillingAddress,
  BreadCrumbs,
  PaymentForm,
  PickupAddress,
  PrimaryGuestForm,
  ProductSummary,
  ProgressFooter,
  SameAsPickup,
} from '../checkout';
import { useCheckoutController } from '../checkout/Checkout.controller';
import { useCheckoutFlowController } from '../checkout/CheckoutFlow.controller';
import {
  CheckboxLabel,
  FlexRow,
  H1,
  H2,
  H3,
  H4,
  P,
  PInfo,
  SlideInTransition,
  StyledCheckbox,
} from '../components';
import { useCheckoutFormController } from '../checkout/CheckoutForms.controller';
import { ReactComponent as Loader } from '../assets/loader.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { useMutation } from '@apollo/client';
import { APPLY_DISCOUNT } from '../graphql/mutations';

// customer info (primary guest/pickup address) -> guest info -> extras -> payment
export const Checkout: React.FC = () => {
  const [qty, setQty] = useState(0);
  const [isBudget, setIsBudget] = useState(false);
  const {
    pageState,
    pageOrder,
    onNext,
    onGoToCrumb,
    highestCompletedStep,
    topRef,
  } = useCheckoutFlowController(isBudget, qty);
  const parsed = queryString.parse(window.location.search);

  const { data, loading, checkoutLoading, reload } = useCheckoutController({
    checkoutId: parsed.checkoutId,
  });

  useEffect(() => {
    if (data?.getDraftOrderById) {
      console.log();
      setQty(+data.getDraftOrderById.quantity);
      setIsBudget(
        data.getDraftOrderById.type === 'budget' ||
          data.getDraftOrderById.type === 'og'
      );
    }
  }, [data]);

  const [applyDiscount, { data: discountData, loading: discountLoading }] =
    useMutation(APPLY_DISCOUNT);

  const {
    primaryGuestFormik,
    pickupAddressFormik,
    billingAddressFormik,
    billingSameAsPickupFormik,
    handleOnSameCheck,
    onCreateOpenOrder,
    handlePhoneChange,
    handleBillingAddressSelect,
    phoneVal,
    googlePlace,
    handleAddressSelect,
    googleLoading,
    openOrderLoading,
    openOrderError,
    termsFormik,
    googlePlaceBilling,
    productSummary,
    discountFormik,
  } = useCheckoutFormController({
    onNext,
    id: parsed.checkoutId,
    items: data?.getDraftOrderById?.items,
    totalAmount: data?.getDraftOrderById?.totalAmount,
    discounts: data?.getDraftOrderById?.discounts,
  });

  const handleApplyDiscount = () => {
    applyDiscount({
      variables: {
        discountInput: {
          draftId: parsed.checkoutId,
          discountCode: discountFormik.values.code,
          qty,
          type: data.getDraftOrderById.type,
        },
      },
    });
  };

  const hasUpgradedToPrivate = primaryGuestFormik.values.upgradeBudgetToPrivate;

  const handleOnNext = () => {
    onNext(hasUpgradedToPrivate);
  };

  if (!parsed.checkoutId) {
    window.location.replace('https://www.ruadeseo.com');
  }

  if (loading)
    return (
      <LoaderContainer>
        <LoaderBox>
          <Loader />
        </LoaderBox>
      </LoaderContainer>
    );
  if (openOrderError)
    return (
      <LoaderContainer>
        <ErrorBox>
          <H1>Hmmm let's try that again...</H1>
          <H3>
            Please click{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={reload}
            >
              here
            </span>{' '}
            to try again
          </H3>
          <P>
            If you are still experiencing issues, please email us at
            info@ruadeseo.com or text/call (858) 704-6525
          </P>
        </ErrorBox>
      </LoaderContainer>
    );
  if (
    data.getDraftOrderById.state !== 'OPEN' &&
    data.getDraftOrderById.state !== 'DRAFT'
  ) {
    window.location.replace('https://www.ruadeseo.com');
  }

  return (
    <Container>
      <LeftPane ref={topRef}>
        <H1>Checkout</H1>
        <BreadCrumbs
          pageState={pageState}
          highestCompletedStep={highestCompletedStep}
          onGoToCrumb={onGoToCrumb}
          hidePickupAddress={isBudget}
        />
        <Content>
          <SlideInTransition
            from="right"
            isIn={pageOrder[pageState] === 'customerInfo'}
          >
            <PrimaryGuestForm
              phoneVal={phoneVal}
              formik={primaryGuestFormik}
              qty={qty}
              handlePhoneChange={handlePhoneChange}
              showUpgradeToPrivate={
                data.getDraftOrderById.showUpgradeToPrivate === 'true'
              }
              minToOperate={data.getDraftOrderById.minToOperate}
              showOptForPublic={
                data.getDraftOrderById.canOptForPublic === 'true'
              }
              isBudget={isBudget}
            />
            <ProgressFooter
              disabled={
                (isBudget && !primaryGuestFormik.values.groupTerms) ||
                !primaryGuestFormik.isValid ||
                !primaryGuestFormik.dirty ||
                !isValidPhoneNumber(primaryGuestFormik.values.phoneNumber)
              }
              onNext={
                isBudget && !hasUpgradedToPrivate
                  ? onCreateOpenOrder
                  : handleOnNext
              }
              pageState={pageState}
              loading={isBudget && !hasUpgradedToPrivate && openOrderLoading}
            />
          </SlideInTransition>
          <SlideInTransition
            from="right"
            isIn={pageOrder[pageState] === 'pickupAddress'}
          >
            <H4 style={{ marginBottom: 0 }}>Pickup Address</H4>
            <PInfo>You can change this later if needed</PInfo>
            <PickupAddress
              formik={pickupAddressFormik}
              googlePlace={googlePlace}
              handleAddressSelect={handleAddressSelect}
              loading={googleLoading}
            />
            <ProgressFooter
              disabled={
                !pickupAddressFormik.isValid || !pickupAddressFormik.dirty
              }
              loading={openOrderLoading}
              onNext={onCreateOpenOrder}
              pageState={pageState}
            />
          </SlideInTransition>
          <SlideInTransition
            from="right"
            isIn={pageOrder[pageState] === 'payment'}
          >
            <div className="margin_bottom_32">
              <H4>Billing Address</H4>
            </div>
            {!isBudget ? (
              <div className="margin_bottom_32">
                <SameAsPickup
                  handleOnSameCheck={handleOnSameCheck}
                  formik={billingSameAsPickupFormik}
                />
              </div>
            ) : undefined}
            <BillingAddress
              googlePlace={googlePlaceBilling}
              handleAddressSelect={handleBillingAddressSelect}
              formik={billingAddressFormik}
              loading={googleLoading}
            />
            <FlexRow
              style={{ maxWidth: '500px' }}
              className="margin_top_24 margin_bottom_16"
            >
              <CheckboxLabel htmlFor="tosAccept">
                I agree to and accept Ruadeseo's{' '}
                <a
                  href="https://ruadeseo.s3.us-west-1.amazonaws.com/Ruadeseo_TOS.pdf"
                  target="__blank"
                >
                  Terms of Service
                </a>
              </CheckboxLabel>
              <StyledCheckbox
                {...termsFormik.getFieldProps('tosAccept')}
                id="tosAccept"
                name="tosAccept"
                type="checkbox"
              />
            </FlexRow>
            <H4 className="margin_bottom_4">Card Details</H4>
            <PInfo className="margin_top_0">
              *Please make sure both zip code fields match and are correct
            </PInfo>

            <PaymentForm
              disabled={
                !billingAddressFormik.isValid ||
                !billingAddressFormik.dirty ||
                !termsFormik.values.tosAccept
              }
              loading={checkoutLoading}
            />
          </SlideInTransition>
        </Content>
      </LeftPane>
      <RightPane>
        <FlexRow style={{ width: '50px', margin: '0 auto' }}>
          <Logo />
        </FlexRow>
        <H2>Summary</H2>
        <ProductSummary
          formik={discountFormik}
          productSummary={productSummary}
          date={data.getDraftOrderById.startDate}
          privateChecked={primaryGuestFormik.values.upgradeBudgetToPrivate}
          onSubmit={handleApplyDiscount}
          loading={discountLoading}
          discountData={discountData}
        />
      </RightPane>
    </Container>
  );
};
