import styled from 'styled-components';

export const Container = styled.div`
  width: 275px;
  height: 750px;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
`;

export const ContainerShort = styled.div`
  width: 275px;
  height: 695px;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
`;

export const LoaderContainer = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  width: 100%;
`;

export const LoaderBox = styled.div`
  width: 50px;
  height: 100%;
  margin: 0 auto;
`;

export const SelectRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  width: 88px;
  margin-left: 16px;
`;

export const Divider = styled.hr`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 24px;
`;

export const PublicKey = styled.div`
  background-color: #b27160;
  border-radius: 0.3rem;
  height: 15px;
  width: 15px;
  margin-right: 8px;
`;
