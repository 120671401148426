import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  min-height: 100px;
  align-items: flex-start;
  flex-direction: column-reverse;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Button = styled.button`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 32px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const BackLink = styled.a`
  width: 100%;
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  cursor: pointer;
`;

export const SkipLink = styled.a`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  cursor: pointer;
`;
