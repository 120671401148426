import styled from 'styled-components';

export const StyledLabel = styled.label`
  margin-bottom: 8px;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
`;

export const CheckboxLabel = styled.label`
  margin-bottom: 16px;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
`;

export const StyledLabelLarge = styled.label`
  font-size: 24px;
  font-family: 'brandon-grotesque', sans-serif;
  color: #333;
  font-weight: 700;
  margin: 16px 0;
`;
