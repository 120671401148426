import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useCallback } from 'react';
import { COMPLETE_PAYMENT } from '../graphql/mutations';
import { GET_ORDER } from '../graphql/queries';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://9a7f7bf3090a453da89aad45083e1b98@o1205088.ingest.sentry.io/6334830',
  release: 'ruadeseo-checkout',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


export const useCheckoutController = ({
  checkoutId,
}) => {

  const [
    completePayment,
    { data: checkoutData, loading: checkoutLoading, error: checkoutError },
  ] = useMutation(COMPLETE_PAYMENT);

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: { id: checkoutId },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  });

  const appId = process.env.REACT_APP_APP_ID;
  const locationId = process.env.REACT_APP_LOCATION_ID;

  async function initializeCard(payments) {
    const card = await payments.card();
    await card.attach('#card-container');

    return card;
  }

  const createPayment = useCallback(async (token, verificationToken) => {
    const cardholderName = document.getElementById('billing-cardholderName').value
    const addressLine1 = document.getElementById('billing-a').value
    const addressLine2 = document.getElementById('billing-a2').value
    const city = document.getElementById('billing-city').value
    const state = document.getElementById('billing-state').value
    const postalCode = document.getElementById('billing-zip').value

    const paymentData =  {
      sourceId: token,
      orderId: checkoutId,
      verificationToken,
      billingAddress: {
        cardholderName,
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode,
        country: 'US'
      }
    };

    Sentry.captureMessage(JSON.stringify({
      op: 'Complete payment data',
      name: 'AttemptCompletePayment',
      data: {
        ...paymentData,
      },
    }))

    const paymentResponse= await completePayment({
      variables: {
        cardPaymentInput: paymentData
      },
    });
    
    const redirectUrl = `${window.location.origin}/${paymentResponse?.data?.squareCheckout?.receiptUrl}`;

    if(redirectUrl) {
      window.location.replace(redirectUrl)
    }

    // console.log(paymentResponse, 'WHATTTTTT');

    // if (paymentResponse.ok) {
    //   return paymentResponse.json();
    // }

    // const errorBody = await paymentResponse.text();
    // throw new Error(errorBody);
  }, [checkoutId, completePayment])

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  async function verifyBuyer(payments, token) {
    const addressLine1 = document.getElementById('billing-a').value
    const addressLine2 = document.getElementById('billing-a2').value
    const city = document.getElementById('billing-city').value
    const state = document.getElementById('billing-state').value
    const postalCode = document.getElementById('billing-zip').value

    const verificationDetails = {
      amount: '100000',
      billingContact: {
        addressLines: [addressLine1, addressLine2],
        city,
        state,
        postalCode,
        countryCode: 'US',
      },
      currencyCode: 'USD',
      intent: 'CHARGE',
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  }

  // status is either SUCCESS or FAILURE;


  function displayPaymentResults(status) {
    const statusContainer = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

  const onDataLoad = useCallback(async () => {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

    // Checkpoint 2.
    async function handlePaymentMethodSubmission(
      event,
      paymentMethod,
      shouldVerify = true
    ) {
      event.preventDefault();
      try {
        // disable the submit button as we await tokenization and make a payment request.
        cardButton.disabled = true;
        const token = await tokenize(paymentMethod);
        let verificationToken;

        if (shouldVerify) {
          verificationToken = await verifyBuyer(payments, token);
        }

        const paymentResults = await createPayment(token, verificationToken);
        displayPaymentResults('SUCCESS');

        console.debug('Payment Success', paymentResults);
      } catch (e) {
        cardButton.disabled = false;
        displayPaymentResults('FAILURE');
        // document.getElementById('cardNumber').value = null
        // document.getElementById('expirationDate').value = null
        // document.getElementById('cvv').value = null
        // document.getElementById('postalCode').value = null
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById('card-button');
    cardButton.addEventListener('click', async function (event) {
      await handlePaymentMethodSubmission(event, card);
    });
  }, [appId, createPayment, locationId]);

  const reload = () => {
    window.location.reload()
  }
  

  useEffect(() => {
    if (data) {
      onDataLoad();
    }
  }, [data, onDataLoad]);

  return {
    data,
    loading,
    error: error || checkoutError,
    checkoutData,
    checkoutLoading,
    reload
  };
};
