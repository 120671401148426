import React from 'react';
import { BreadCrumbButton, BreadCrumbContainer } from './BreadCrumbs.styles';

interface BreadCrumbsProps {
  onGoToCrumb: (e: any) => void;
  highestCompletedStep: number;
  pageState: number;
  hidePickupAddress: boolean;
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  onGoToCrumb,
  highestCompletedStep,
  pageState,
  hidePickupAddress,
}) => {
  return (
    <BreadCrumbContainer>
      <BreadCrumbButton
        onClick={onGoToCrumb}
        data-item="0"
        isActive={pageState === 0}
      >
        Customer Information /
      </BreadCrumbButton>
      {!hidePickupAddress && (
        <BreadCrumbButton
          onClick={onGoToCrumb}
          data-item="2"
          disabled={highestCompletedStep < 2}
          isActive={pageState === 2}
        >
          Pickup Address /
        </BreadCrumbButton>
      )}
      <BreadCrumbButton
        onClick={onGoToCrumb}
        data-item="3"
        disabled={highestCompletedStep < 3}
        isActive={pageState === 3}
      >
        Payment
      </BreadCrumbButton>
    </BreadCrumbContainer>
  );
};
